<template>
    <div id="Thanks">
        <section id="thanks-banner" class="banner-section-style">
            <div class="mobile-title hide">
                <h1 class="f20-mb">界員の声・親感謝の言葉</h1>
            </div>
            <div
                class="banner"
                :style="
                    addBackgroundImage(
                        require('@/assets/background/background-1.jpg'),
                        require('@/assets/background/background-1-mb.jpg')
                    )
                "
            ></div>
        </section>
        <section id="thanks-title" class="title-section-style hide-mb">
            <h2 class="title f29">界員の声・親感謝の言葉</h2>
        </section>
        <section id="thanks-main">
            <div class="wrap w60 w80-mb">
                <article
                    v-for="(item, index) of thanksArticles"
                    :key="'thanks-article' + index"
                >
                    <header>
                        <div class="avatar" :class="item.gender"></div>
                        <h2 class="f14 f12-mb">{{ item.name }}</h2>
                    </header>
                    <div class="summary">
                        <p class="f14 f12-mb" v-html="wrap(item.summary)"></p>
                    </div>
                    <main v-if="item.articles" :id="'thanks-article-' + index">
                        <ul class="jump-list-style">
                            <li
                                v-for="(subitem, subindex) of item.articles"
                                :key="
                                    'thanks-article-' +
                                    index +
                                    '-' +
                                    subindex +
                                    '-list'
                                "
                                @click="
                                    scrollToElement(
                                        '#thanks-article-' +
                                            index +
                                            '-' +
                                            subindex
                                    )
                                "
                            >
                                <i class="icon-page-link f12 f12-mb"></i
                                >{{ ' ' + subitem.title }}
                            </li>
                        </ul>
                        <div
                            class="thanks-article-div"
                            v-for="(subitem, subindex) of item.articles"
                            :id="'thanks-article-' + index + '-' + subindex"
                            :key="'thanks-article-' + index + '-' + subindex"
                        >
                            <h3 class="f16 f14-mb bold">
                                {{ subitem.title }}
                            </h3>
                            <p
                                class="f14 f12-mb"
                                v-html="wrap(subitem.article)"
                            ></p>
                        </div>
                    </main>
                    <main
                        v-else-if="item.article"
                        :id="'thanks-article-' + index"
                    >
                        <p class="f14 f12-mb" v-html="wrap(item.article)"></p>
                    </main>
                    <footer
                        @click="toggle(index)"
                        :class="{ expanded: stretchedArticle.includes(index) }"
                    >
                        <p
                            v-show="!stretchedArticle.includes(index)"
                            class="f14 f12-mb serif"
                        >
                            詳しく見る
                        </p>
                        <i class="icon-expand"></i>
                        <p
                            v-show="stretchedArticle.includes(index)"
                            class="f14 f12-mb serif"
                        >
                            詳しく見る
                        </p>
                    </footer>
                </article>
            </div>
        </section>
    </div>
</template>
<script>
import { reactive } from '@vue/reactivity'
import { addBackgroundImage, wrap, scrollToElement } from '@/utils/common.js'
import getThanksData from '@/data/getThanksData.js'
export default {
    name: 'Thanks',
    setup() {
        const { thanksArticles } = getThanksData()
        let stretchedArticle = reactive([])
        function toggle(index) {
            let el = document.getElementById('thanks-article-' + index)
            if (stretchedArticle.includes(index)) {
                stretchedArticle.splice(stretchedArticle.indexOf(index), 1)
                el.style.height = '0'
                window.scrollTo({
                    top: el.parentNode.offsetTop - 78,
                    left: 0,
                    behavior: 'smooth',
                })
            } else {
                stretchedArticle.push(index)
                el.style.height = el.scrollHeight + 'px'
            }
        }
        return {
            addBackgroundImage,
            wrap,
            scrollToElement,
            thanksArticles,
            toggle,
            stretchedArticle,
        }
    },
}
</script>
<style lang="scss" scoped>
#thanks-main {
    background-color: $minorGold;

    article {
        background-color: white;
        border: 3px solid $majorPurple;
        border-radius: 20px;
        margin: 50px 0;
        box-shadow: $shadow2;
    }
    header {
        display: flex;
        align-items: center;
        .avatar {
            flex: 0 0 60px;
            margin-right: 25px;
            height: 60px;
            background: center / contain no-repeat;

            &.male {
                background-image: url('~@/assets/thanks/male.png');
            }
            &.female {
                background-image: url('~@/assets/thanks/female.png');
            }
        }
    }
    .thanks-article-div {
        h3 {
            margin-top: 2em;
            line-height: 3em;
        }
    }
    .summary {
        margin-top: 2em;
    }
    main {
        height: 0;
        overflow: hidden;
        transition: 1s ease-in-out;
    }
    footer {
        text-align: center;
        cursor: pointer;
        color: $gray6;
        i {
            display: block;
            font-size: 12px;
            line-height: 1em;
        }
        &.expanded {
            i {
                display: block;
                transform: rotate(180deg);
            }
        }
    }
    @media screen and (min-width: 768px) {
        padding: 100px 0;
        article {
            padding: 50px 30px 30px;
        }
        main {
            margin: 30px 0;
        }
    }
    @media screen and(max-width:767px) {
        padding: 10px 0;
        article {
            padding: 30px;
        }
        main {
            margin: 20px 0;
        }
    }
}
</style>
