export default function () {
    const thanksArticles = [
        {
            gender: 'male',
            name: '山田政弘　命霊',
            summary:
                '最初は、大自然界の教えを宗教だと誤解していました。しかし実際にお授け頂いた生き方を毎日実践すると、それまで嫌だった仕事が、プラスに楽しめるようになりました。また、妻や子供の事を一層愛おしく感じるようになりました。『大親神大生命』に対し奉り親感謝の気持ちでいっぱいです。',
            articles: [
                {
                    title: '最初は宗教だと誤解していました',
                    article:
                        '当時、家族四人のうち自分以外、つまり妻と子供達二人は、毎週日曜日になると『大親神大生命』の貴き親理のお授けを頂くために神奈川県の大船から東京代々木まで出かけていきました。\n \
                        私はと言えば、朝、近くの駅まで三人を車で送った後は、日曜ゴルフに勤しんでおりました。妻からは「一度で良いから、一緒に行ってくださいね」とずっと言われていたのですが、宗教だと誤解していたので、ずっと抵抗しておりました。\n \
                        \n \
                        ところが、ちょうど一年過ぎたころ、ふと「そんなに言うなら、行ってみようかな」という気持ちにならせて頂きました。それというのは、「こんなに素晴らしい親理のお授けを、どうしても家族みんなで学びたい」という、妻の前向きな強い気持ちを感じたからです。\n \
                        \n \
                        妻は『大親神大生命』の貴き親理のお授けのテープを、私が座っている横に置いていってみたり、寝ている横にも置いてみたりして「なんとか聞いてもらいたい」と真剣でした。彼女は「親理のお授け」を頂いたら、本当に素晴らしくなれると実感していたのだと思います。私は、自分のことをこんなに真剣に思ってくれている一途な妻の熱意にうたれました。',
                },
                {
                    title: '実際に貴き親理のお授けを頂いてみると･･･',
                    article:
                        '『大親神大生命』のお授けを初めて頂いた時のことは、今でもしっかりと覚えております。その時に『大親神大生命』は「自分の子供はつくらないが神の子をつくる」とお授けくださった事が、心に強く響きました。\n \
                        人間は自分の子供をつくって育てるけれど、『大親神大生命』は神の子をおつくりになる、みんなを神の子に育成してくださるというのは、本当に凄いなあ、素晴らしいなあと思いました。\n \
                        自分も自分の為にではなく、他の為にと思えるようになりたい、そんな風に変わりたいな、お授けを頂けば変われるかもしれない、と感じました。\n \
                        \n \
                        『大親神大生命』の貴き親理のお授けをテープで頂き始めてから、一年以上過ぎた時に、「この教えは何か普通と違うな、自分も直接お授けを頂きたいな」と思い、正式に入会いたしました。それは妻や子供たちが変わったのを目の当たりにしたからです。\n \
                        \n \
                        当時の私は仕事が忙しく、夜遅く帰宅し、また朝早く会社に行くという会社人間だったので、あまり家族愛というものが分かりませんでした。しかし妻と子供達がお授けを頂くようになってから、以前と違って、家中の全てが明るいというか、愛情溢れる温かい家になった感じがいたしました。それで自分も変わりたいと思い、勉強を始めました。',
                },
                {
                    title: '当時は仕事も家庭も上手くいかない日々',
                    article:
                        '入会した当時、私は39歳でした。この年の7月1日に管理職に昇格しましたが、あまりの忙しさに「こんな会社辞めてやる！」と上司に言ったほどです。とにかく会社が嫌で嫌で、「どこかにもっと良い会社があるに違いない、辞めよう！」と毎日思っていました。\n \
                        \n \
                        仕事が忙しすぎて、家庭をかえりみることもありませんでした。疲れ切った体で真夜中に帰宅しますので、当然、妻や子供達とも会話が無くなりました。妻は「どうしたの？」と心配して聞いてくれるのですが、答える気力もありません。会社を辞めたかったのですが、そんなこと妻には言えませんでした。\n \
                        \n \
                        それから2～3か月間くらい、私の方から口をきく事はほとんど有りませんでした。妻や子供達に会社のことを言っても分かってもらえない、と自分で勝手に思い込んだからです。私が全く口をきかないので、妻や子供達には心配をかけたり、寂しい思いをさせてしまいました。',
                },
                {
                    title: 'プラス思考で仕事が楽しめるようになりました',
                    article:
                        '「こんな会社辞めてやる！」とずっと思っていたのですが、『大親神大生命』の親理のお授けを頂き「仕事が楽しい！」「今日も会社に行って頑張るぞ！」というプラスの気持ちに変わりました。\n \
                        \n \
                        きっかけは、初めて界の「真性海水浴」に参加させて頂いた時のことです。その日は雨が降っていたのですが、レクリエーションなども用意していたので「大丈夫かな…」と、雨の中で海水浴を行うことをマイナスに思っていました。\n \
                        \n \
                        その時『大親神大生命』が「雨もまた楽しからずや」というお授けをして下さいました。「どうせ海水浴で濡れるのだから、雨で濡れることも味わいとして、喜び楽しみなさい」というお授けでした。私は大きな衝撃を受けました。\n \
                        \n \
                        このお授けをいただいた時に、「どうせ仕事するのなら楽しくやりなさいよ」という事だと気付かせていただきました。それまであれほど嫌だった仕事が、プラスに思えたのです。マイナス思考の自分が、こんな風に変わらせていただき、『大親神大生命』に対し奉り親感謝の気持ちでいっぱいです。',
                },
                {
                    title: '妻や子供が一層愛おしくなりました',
                    article:
                        '妻に対する気持ちも変わりました。妻のことが、とても大事な存在で、絶対にいてほしい存在だと、本当にそう思えるのです。毎日が新鮮で、素晴らしい気持ちです。妻と二人で「54年目の新婚生活だね」と笑いました。\n \
                        \n \
                        『大親神大生命』より「地球上の夫婦問題の切り換えの種になるので、恥ずかしがらず素直な気持ちで、ぜひ実行してみてください！」という親御指導を頂いて、「朝の褒め言葉」を実行させて頂いております。「君は素敵だね」「l love you！」「子供は私の宝だ！」と、気持ちを言葉にして伝えます。海外では、普段からそういう言葉を言うそうですね。しかし自分はお授け頂くまで一度も言ったことがありませんでした。\n \
                        \n \
                        毎日実践するたびに、『大親神大生命』より素晴らしい家族をお与え頂いた喜びと親感謝の気持ち、そして妻が、子供達が、ますます愛おしく、大切に思う気持ちが強くなっていきます。こんなに素晴らしい気持ちは、実行して初めて分からせて頂きました。これはすごいな、素晴らしいなあと実感しております。\n \
                        \n \
                        もしも『大親神大生命』より親理のお授けを頂けなかったら、よくお授けの中の例題にある通り、今頃は会社・家族から逃げて山谷のドヤ街に行っていたと思います。それが全く逆の運命になってしまったんです！\n \
                        『大親神大生命』より貴き親理のお授けを頂いたからこそ変わらせて頂くことができました。有り難うございました、厚く厚く御礼申し上げます。',
                },
            ],
        },
        {
            gender: 'female',
            name: '田中幸子　命霊',
            summary:
                '以前は心配したり、くよくよしたり、物事をマイナスに考えがちで、持病も沢山抱えていました。真理法則のお授けを頂くようになってからは、マイナスからプラスへ、全然考え方が変わりました。主人に対するイライラ・不足不満が、感謝・尊敬に変わり、自分の心も安定し、病気もすっかり治して頂きました。素晴らしい主人と子供に恵まれた明るい家庭となり、毎日が生きる感謝と喜びでいっぱいです。',
            articles: [
                {
                    title: '心の切り換え（コペルニクス的観念の転換）',
                    article:
                        '私は以前、十二指腸潰瘍、コレステロール血症、偏頭痛、冷え性と、いろいろな病気をもっていました。\n \
                        ところが幸運にも、大自然の教えのお勉強をさせて頂いてから、みるみるうちに良くなりました。薬も全然飲まないのに、このように病気をすっかり治して頂けた事に、改めて精神波動の偉大さに驚きますとともに、親感謝の気持ちでいっぱいです。\n \
                        \n \
                        思い返してみますと、以前は「マイナス」の神経ばかりを使っていました。心配したり、くよくよしたり、とかく物事を「マイナス」に考えがちでした。\n \
                        特に主人に対しては頼りなく思い、天下ぶりを発揮していました。お酒が好きな主人ですので、飲んでくる事も多く、夜遅く帰ってくると無性に癪に障りました。また、車の運転をしてくれている横に座っては、はらはらしたり、自分は運転もできないのに「ああだ・こうだ」と余計な口出しをしていました。そして自分の思うようにならないと愚痴になったり、主人のやること・なすこと全てが気に障り、不足・不満になっていました。\n \
                        \n \
                        ところが、真理法則のお授けを頂くようになってからは、全然考え方が変わりました。\n \
                        実生活、家庭を修行の場と考えて、『大親神大生命』が巡り合わせて下さった主人に不足・不満を言っていた自分に問題があると思い、一歩一歩必死でやらせて頂きました。\n \
                        \n \
                        それまでは人様へ責任を転嫁する考えばかりしていましたが、自己の観念を切り換える事により、「マイナス」が起こったらそれは自分の責任だと思うように努力しました。例えば頭が痛くなったら「自分が悪い考えをしているからだ」と思い、子供が熱を出したら「私がカッカしたからだ」と思い、『大親神大生命』に「申し訳ありません。考え方を改めさせて頂きます」とお詫びを申し上げました。\n \
                        \n \
                        「文句を言いたい時にこそ、笑って通る事が大切です」という『大親神大生命』のお授けが、他人に対しては出来るのに、家庭の中ではとても難しく、初めの頃は顔が引きつってなかなか出来ませんでしたが、無理にでも笑うように頑張りました。そして「マイナス」を「プラス」に考えるように努力しました。\n \
                        \n \
                        主人がお酒を飲んで帰ってきても、無事に帰ってきてくれたその事実に感謝出来るようになった時「遅くまで大変だったわね、ご苦労様でした」という言葉が自然に口から出てきました。\n \
                        \n \
                        相手から文句を言われても、「これは私を伸ばして下さる『大親神大生命』のお言葉だ」と受け取れるようになれたら、腹も立たなくなりました。「これが出来たら一歩前進だ」と思うと、文句を言ってくれた相手に対して、自然に感謝の気持ちが湧いてくるようになりました。\n \
                        \n \
                        そして出来るだけ相手の良い点を見るようにして、気持ちの暗くなりそうな時には、明るく通るよう必死で努力しました。自分が努力して相手が少しでも「プラス」の気持ちに変わってくれた時には、本当に嬉しく思い、『大親神大生命』に「ありがとうございました」と御礼を申し上げました。\n \
                        \n \
                        小さな努力を積み重ねる度に、一つ一つ解決していく自分の人生問題をみていますと、努力する事がとても楽しくなってきました。\n \
                        \n \
                        その頃から、主人もお授けを頂きたいと、お勉強に行かせて頂くようになりました。主人と二人揃って勉強させて頂くようになってから、家庭の中は増々明るくなり、お互いに尊敬し合えるようになりました。そしていつの間にか自分の心も安定し、その時には病気の方もすっかり治して頂きました。\n \
                        \n \
                        今ではおかげ様で、素晴らしい主人と子供に恵まれた楽しく、明るい家庭となり、毎日が生きる感謝と喜びでいっぱいです。',
                },
            ],
        },
        {
            gender: 'female',
            name: '村松信子　命霊',
            summary:
                '私はキリスト教の家庭に育ちましたが、敬虔なクリスチャンではありませんでした。キリスト教の内容の矛盾や宗派の違いに、色々と小さな疑問を感じていたからです。ある時から自己嫌悪でひどいノイローゼになり、苦しい日々を過ごしましたが、『大親神大生命』の事実のお授けを頂いて、生きる喜び味わいを持たせて頂けるようになりました。また、キリスト教の祈りは人間が勝手に考えた祈りだと判らせて頂きました。『大親神大生命』の大いなる生命のお力・お働きと生命愛によって活かされ生きている事実を分からせて頂き、親感謝と真感動しかございません。',
            articles: [
                {
                    title: 'クリスチャンの家庭で育った子供時代',
                    article:
                        ' 私は父母がキリスト教の一派「聖公会」の会員というクリスチャンの家庭に育ち、幼児洗礼も受けました。子供の時にはクリスマス（キリストの降誕祭）、イースター（キリストの復活祭）を楽しんでいました。大人の礼拝の前に、子供たち向けの「日曜学校」があり、小学四・五年生くらいになると日曜学校に行き、聖歌を歌ったり、聖書の話を学んだりしていました。\n \
                        \n \
                        聖公会には「祈祷書」というお祈りの文があって、その中でも「使徒信教」と「主の祈り」をよく唱えていました。中学生の時には、日曜学校の先生から「主の祈りは大切な祈りだから覚えなさい」と言われて、暗記しました。',
                },
                {
                    title: '敬虔なクリスチャンにはなれませんでした',
                    article:
                        '子供の頃はキリスト教しか知らずに育ったわけですが、大きくなってからは、そんなに敬虔なクリスチャンというわけではありませんでした。色々と小さな疑問点があったからです。\n \
                        \n \
                        例えば、学校の歴史の授業で進化論を習いますが、キリスト教では「神が土をこねてアダムを創り、アダムのあばら骨一本からイヴを創った」とされています。宗教というのは何かおかしな所があるな、と感じていました。\n \
                        \n \
                        キリスト教では「エデンの園でイヴが蛇にそそのかされて禁断の木の実を食べ、神の怒りに触れエデンの園から追放された。人類はアダムとイヴの子孫であるから、生まれながらにして罪の子である」とされていて、罪を許し給えと祈っています。\n \
                        \n \
                        この「罪」という事について、子供の頃はそんなに深く考えていませんでした。人間は自分の都合で人を誤魔化したり、嘘をついたり、人を妬んだりとか、日常的にしますよね。そういう事について「申し訳ありません」と謙虚にお詫びしている、くらいに思っていたんです。\n \
                        \n \
                        でも短大に行った時に「キリスト教学」の授業を受けて、そこで初めて「原罪」という事を知りました。私は、このアダムとイヴの話は「神話」であり、実際の事実とは違うと思っていましたので、アダムとイヴが禁断の木の実を食べたからといって、なぜ私たちが罪の子なのか？と納得できませんでした。\n \
                        \n \
                        また、キリスト教は愛を説きながら争いが絶えません。旧教と新教の争い、中東への十字軍の派遣･･･争いは今も続いています。それがあって、キリスト教に素直になれませんでした。\n \
                        自分の子供をキリスト教の幼稚園に入れた時にも、「教会員になりなさい」と言われましたが、なれなかったんです。キリスト教はあまりにも宗派があり過ぎて、一体どれが本当なのか、どれが一番信じられるのか、という思いがありました。',
                },
                {
                    title: '大自然の教えとの出会い',
                    article:
                        'ある時から、私は自己嫌悪で大変ひどいノイローゼになり、今日一日どうやって生きたら良いか、苦しい日々を過ごしておりました。あまりにもひどい状態だったので、主人が困って私の姉に相談したところ、大自然界の界員だった姉が、大自然の教えを勧めてくれました。\n \
                        \n \
                        姉に伴われて、初めて『大親神大生命』の親理のお授けを頂きました。その後の真和会（お茶の席）で『大親神大生命』が「主の祈りほど変な祈りはない」と仰られたので、私は少し驚きというか、不思議な気持ちになりました。というのも、ちょうどその数日前に「主の祈りほど素晴らしい祈りはない」という講演を聞いてきたばかりだったからです。\n \
                        \n \
                        その講演会は、娘の通っていたミッションスクールで、保護者の為に行われたものでした。講師はクリスチャンでドイツ文学の大学教授でした。無神論者の先輩が若くして死の床につき、死を恐れていた時に、「主の祈り」を説き伝えたら、彼は安心して死を受け入れて亡くなられた、というお話でした。\n \
                        その数日後に、『大親神大生命』より「主の祈りほど変な祈りはない」という御言葉を頂いたわけです。講演会の事を私は一言も申し上げていなかったのに、そのような御言葉を賜ったので、それが疑問で不思議でした。',
                },
                {
                    title: '『大親神大生命』の事実のお授けを頂いて、生きる喜び味わいを持たせて頂きました',
                    article:
                        '大自然界の教えは、生きる事の事実をわからせて頂ける教えです。『大親神大生命』の事実のお授けを何回も頂くうちに、『大親神大生命』という生命の御親の御存在、御心、大親の愛を、少しずつ分からせて頂いております。\n \
                        \n \
                        全ての生命は『大親神大生命』の貴い生命の一部を頂いた生命の御子なのだ、『大親神大生命』は大きな生命の愛をもって私たち全生物を生かして下さっているのだ、という、『大親神大生命』の真実理（真実、事実）を分からせて頂くと、少しずつ気持ちがプラスに転換させて頂き、生きる喜びと味わいを持たせて頂けるようになりました。『大親神大生命』に対し奉り、親感謝しかございません。',
                },
                {
                    title: 'キリスト教の祈りは人間の勝手な祈りだと判らせて頂きました',
                    article:
                        '『大親神大生命』は全ての生命の御子らを大自然の理法に沿って生まれさせて下さっているのに、キリスト教では、キリストは聖霊によってやどり、おとめマリアから生まれたと言っています。\n \
                        また「主の祈り」におきまして、神に対して感謝して生きる御礼を申し上げるという事がなく、「～して下さい」と願い祈っています。「天の神の国で行われている様に人間の生きている地にも行って下さい」「今日のパンを与えて下さい」「罪を犯している者を我らがゆるしている様に神様も我らの罪をゆるして下さい」「試練に遭わせないで悪より救って下さい」と祈っています。感謝する事も、努力する決意もなく、神に対して不遜であり、変な祈りであると段々と分からせて頂いて参りました。\n \
                        \n \
                        『大親神大生命』より「生きる事実の理」のお授けを頂いて、『大親神大生命』の大いなる生命のお力・お働きと大親の愛・御心によって、全生物が活かされ生きている事を分からせて頂き、親感謝と真感動しかございません。と同時に、キリスト教の祈りは、人間が作ったあまりにも勝手な祈りだった事を分からせて頂きました。そして、このような間違った生き方を、キリスト教が広まった為に、たくさんの人々がしてしまっている事を、『大親神大生命』に対し奉り本当に申し訳なく、生命より深く深くお詫び申し上げます。\n \
                        \n \
                        至りません私ですが、少しでも多くの方に、『大親神大生命』の親御存在を、そして、『大親神大生命』のお力・お働きで活かされ生きているという素晴らしい事実を、お伝えさせて頂きたいです。',
                },
            ],
        },
    ]
    return { thanksArticles }
}
